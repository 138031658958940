var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "div",
                      _vm._g(
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.showStopAppModal.apply(null, arguments)
                            },
                          },
                        },
                        on
                      ),
                      [_vm._t("default")],
                      2
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [_vm._v(" Stop application ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }